import styles from './IndexPage.module.scss'

const IndexPage = () => {
  return (
    <section className={styles.section}>
      <div className={styles.plug}>
        <img src="/images/index_plug.png" alt="" />
        <span className={styles.plug_text}>
          Оплата парковки <br /> в твоем телефоне
        </span>
      </div>
      <div className={styles.links}>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/requisite_hp.pdf"
        >
          Реквизиты магазина
        </a>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/security_policy.pdf"
        >
          Политика безопасности
        </a>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/refund_policy.pdf"
        >
          Политика возвратов платежей
        </a>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/conf_agreement.pdf"
        >
          Политика конфиденциальности
        </a>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/pravila_oplaty.pdf"
        >
          Правила оплаты и безопасность платежей
        </a>
        <a
          target="_blank"
          href="https://crm.hippoparking.ru/docs/user_agreement.pdf"
        >
          Пользовательское соглашение
        </a>
      </div>

      <div className={styles.image}>
        <img src="https://i.ibb.co/KzcRGwD/image-444.png" alt="bank services" />
      </div>
    </section>
  )
}

export default IndexPage
